<template src="./OvasTeachers.html"></template>

<script>
import VueGl from "../../components/VueGl/VueGl";

export default {
  name: "ovasTeachers",
  components: {
    VueGl,
  },
  data() {
    return {
      role: "",
      userId: "",
      dataTeacher: "",
    };
  },
  destroyed() {
      location.reload();
  },
  mounted() {
    this.role = this.$store.state.User.user.role.name;
    this.userId = this.$store.state.User.user.id;
  },
  methods: {
    sendText() {
      const infoTeacher = {
        token: this.$store.state.User.tokenControllerPhp,
        tokenNode: this.$store.state.User.jwt,
        userId: this.userId,
      };
      this.dataTeacher = JSON.stringify(infoTeacher);
      console.log("el profe", this.dataTeacher);
      this.$refs.vuegl.message("Bridge", "SendToUnity", this.dataTeacher);
    },
    fullscreen() {
      this.$refs.vuegl.fullscreen();
    },
  },
  computed: {
    roleValidation() {
      return ["Teacher","TeacherInvestigator"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./OvasTeachers.scss"></style>